export const ENV_VARS_LOCAL = {
  VUE_APP_ENV: "development",
  VUE_APP_IAM_REALM: "swascan",
  VUE_APP_IAM_CLIENT_ID: "swascan",
  VUE_APP_I18N_LOCALE: "it",
  VUE_APP_I18N_FALLBACK_LOCALE: "it",
  VUE_APP_NAME: "frontend",
  VUE_APP_OLD_PLATFORM: "https://staging1.swascan.com/",
  VUE_APP_RESET_PASSWORD: "https://staging1.swascan.com/accounts/password/reset/",
  VUE_APP_SIGN_UP: "https://staging1.swascan.com/accounts/signup/",
  VUE_APP_PERMITTED_LICENSES: "dti,nscan,phishing_attack,smishing_attack,ir,cti,cyber_threat,tvm,dti_lite,webapp,rri,cardp,ict,survey_ict,survey_gdpr,survey_test",
  VUE_APP_IAM_BASE_URL: "https://iam.platform-staging.swascan.com/auth",
  VUE_APP_API_BASE_URL: "https://core.platform-staging.swascan.com/core",
  VUE_APP_BACKEND_BASE_URL: "https://backend.platform-staging.swascan.com",
  VUE_APP_BACKEND_CORE_BASE_URL: "https://backend.platform-staging.swascan.com/core",
  VUE_APP_LICENSE_BASE_URL: "https://licensing.platform-staging.swascan.com/licensing",
  VUE_APP_REST_BASE_URL: "https://rest.platform-staging.swascan.com",
  VUE_APP_SWASCAN_INFO_EMAIL: "mailto:site@swascan.com",
  VUE_APP_VULNERABILITY_EARLY_WARNING_ENV_KEY: "STAGING",
  VUE_APP_FORCE_KEYCLOAK: "false",
  VUE_APP_FORCE_STANDALONE: "true",
  VUE_APP_PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyh9e4qbCZIQOg1TWEO+5vf1PkbUBkvAFsVScbMxx9oiGf/O5i70PyRYQsC0KrcP1GAHwBDdD9/3Bms5EPBMSc/wTvXY4Y1/Zl8rMHSY+HIQ1Dfa1XFWxggfbV6H5qJFzFP4vAnrTuMrEVUGhCXfE5y7wByoeWaGDfMHJslGBdgdBBZqJ1xUdC9lTGmRXn3E4oBqflBHg/c1aktlkl1uHBAIkPNGzCTJBb7POQaIhNDF3cSiEPiuSlgAcRXxYJwh52Eboju3muxi4XURqn29pQZojrJ6E/QabvPmohvOaRGk6mAi0YM8WvXZB7Q0lLYLlL2mzIy29zXa6jfO+WCc/dwIDAQAB
-----END PUBLIC KEY-----`,
};
