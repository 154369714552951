import { createI18n } from "vue-i18n";
import en from "./en";
import it from "./it";

const language = navigator.language ? navigator.language.split("-") : ["en"];

export const i18n = createI18n({
    locale: language[0],
    fallbackLocale: "en",
    messages: {
        en: en,
        it: it,
    },
});
