import { ENV_VARS_KUBE } from "./env_vars.kube";
import { ENV_VARS_LOCAL } from "./env_vars.local";
import { ENV_VARS_STAGING } from "./env_vars.staging";
import { ENV_VARS_PREPROD } from "./env_vars.preprod";
import { ENV_VARS_PROD } from "./env_vars.prod";
import { ENV_VARS_ON_PREM } from "./env_vars.on-prem";

let vars;
switch (process.env.VUE_APP_MODE) {
  case "local":
    vars = ENV_VARS_LOCAL;
    break;
  case "staging":
    vars = ENV_VARS_STAGING;
    break;
  case "preprod":
    vars = ENV_VARS_PREPROD;
    break;
  case "prod":
    vars = ENV_VARS_PROD;
    break;
  case "on-prem":
    vars = ENV_VARS_ON_PREM;
    break;
  case "kube":
  default:
    vars = ENV_VARS_KUBE;
    break;
}

export const ENV_VARS = vars;
