const AppColors = {
  green: "#04B87C",
  red: "#cd1619",
  red20: "#cd161933",
  darkRed: "#8c182a",
  yellow: "#FFB622",
  blue: "#057BC1",
  lightBlue: "#03A9F4",
  darkBlue: "#051b33",
  background: "#414240",
  confidentiality: "#8d37ff",
  integrity: "#e73eff",
  availability: "#ff33ae",
};

module.exports = AppColors;
