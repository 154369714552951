export const translateMethod = {
    translate(suffix, key) {
        if (this.$te(`${suffix}.${key}`)) {
            return this.$t(`${suffix}.${key}`);
        }
        return `${key}`;
    },
    translateWithoutSuffix(key) {
        const value = this.$te(`${key}`) === true ? this.$t(`${key}`) : key;
        console.log("VALUE", value);

        return value;
    },
    translateExists(key) {
        return this.$te(`${key}`);
    },
};
