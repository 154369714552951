import { License } from "@/models";
import { LicenseService } from "@/services";

export const licensesMethods = {
  async getLicenses() {
    try {
      const licenseService = new LicenseService();
      const response = await licenseService.get("", null);
      const licenses = [];
      if (response) {
        for (const license of response.data) {
          const l = new License(license);
          licenses.push(new License(license));
        }
      }
      this.setState({ licenses });
    } catch (error) {
      console.error("ERROR GETTING LICENSES", error);
    }
  },
  checkLicense(engine, callback = null) {
    if (!this.state.licenses || this.state.licenses.length === 0) {
      return setTimeout(() => this.checkLicense(engine), 200);
    }
    const row = this.state.licenses.find((l) => l.engine === engine);
    if (!row) {
      if (callback) {
        return callback();
      }
      return this.$router.back();
    }
  },
};
