import { Engine } from "../utilities";

export const targetsMethods = {
  getRoute(engine = null) {
    switch (engine) {
      case Engine.DTI:
        return "newDTI";
      case Engine.DTI_LITE:
        return "newDTILite";
      case Engine.NETWORK_SCAN:
        return "newNScan";
      case Engine.CTI:
        return "newCTI";
      case Engine.WEB_APP_SCAN:
        return "newWebScan";
      case Engine.RRI:
        return "newRRI";
      default:
        return "newTarget";
    }
  },
  goToAddTarget(engine = null, licenses = null) {
    if (!licenses) {
      return;
    }
    const route = this.getRoute(engine);
    const license = licenses.find((el) => el.engine === engine);
    const query = license ? { engine: license.api } : null;
    this.$router.push({ name: route, query });
  },
};
