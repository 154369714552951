import { Engine } from "../utilities";
import { Target } from "../models";
import { ENV_VARS } from "@/env_vars";

export const data = {
  selectedTarget: null,
  selectedTargetId: null,
};

export const watch = {
  selectedTarget(newVal, oldVal) {
    if (newVal != null) {
      this.$swal({
        title: `${this.selectedTarget.name}`,
        html: this.$t("pages.targets.confirm_body"),
        showConfirmButton: true,
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: `${this.selectedTarget.name}`,
            html: this.$t("pages.targets.processing"),
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              this.$swal.showLoading();
            },
            didOpen: () => {
              setTimeout(() => {
                this.onExecuteTest();
              }, 500);
            },
            didClose: () => {
              this.hideExecuteTest();
            },
          });
        } else {
          this.hideExecuteTest();
        }
      });
    } else {
      this.selectedTargetId = null;
    }
  },
};

export const methods = {
  clickContactUsLink() {
    const link = document.createElement('a');
    link.id = 'contact-us-link';
    link.href = 'mailto:info@swascan.com';
    document.body.appendChild(link)
    document.getElementById('contact-us-link').click();
    document.getElementById('contact-us-link').remove();
  },
  handleSelectedTarget(data) {
    this.selectedTargetId = data.id;
    switch (data.engine_abbreviation) {
      case Engine.DTI:
      case Engine.DTI_LITE:
      case Engine.RRI:
        this.onSetSelectedTarget(data);
        break;
      case Engine.CTI:
        this.$router.replace({
          name: "cti_settings",
          params: {
            targetId: data.id,
            engine: data.engine_abbreviation,
          },
        });
        break;
      case Engine.NETWORK_SCAN:
        this.$router.replace({
          name: "scan_settings",
          params: {
            targetId: data.id,
            engine: data.engine_abbreviation,
          },
        });
        break;
      case Engine.WEB_APP_SCAN:
        this.$router.replace({
          name: "webscan_settings",
          params: {
            targetId: data.id,
            engine: data.engine_abbreviation,
          },
        });
        break;
    }
  },
  onSetSelectedTarget(data) {
    this.selectedTarget = data;
  },
  onExecuteTest(engine, scan_config_id) {
    //* CHECK LICENSES
    const license = this.state.licenses.find((l) => l.engine === this.selectedTarget.engine_abbreviation);
    if (!license || license.remaining_scans <= 0) {
      return this.$swal({
        title: null,
        html: this.$t("errors.no_remaining_scan"),
        icon: "error",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.$t('general.go_on'),
        cancelButtonText: this.$t('general.contact_us'),
        confirmButtonColor: '#3085d6',
        cancelButtonColor: 'rgb(48, 133, 214)',
        reverseButtons: true,
      }).then((result) => {
        this.selectedTarget = null;
        if (result.isConfirmed) {
          //-- Silence is golden
        } else if (result.dismiss === 'cancel') {
          this.clickContactUsLink()
        }
      });
    }

    // INIT
    let body = {
      task_name: this.selectedTarget.name,
      target_id: this.selectedTarget.id,
      target_extra: this.selectedTarget.extra,
      ...(scan_config_id ? { scan_config_id } : {}),
    };

    let args = null;

    //* EXEC
    this.taskService
      .post(this.selectedTarget.engine, body, args)
      .then((response) => {
        this.selectedTargetId = null;
        this.subtractScanToLicense(this.selectedTarget.engine_abbreviation, response);
        this.$swal({
          title: this.$t("general.started") + "!",
          html: this.$t("general.test_started") + "!",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          this.$router.push({
            name: "reports",
            params: { engine: this.selectedTarget.engine_abbreviation },
          });
        });
      })
      .catch((error) => {
        this.selectedTarget = null;
        if (error.response && error.response.data) {
          let message = this.$t("errors.wrong_targets_format");
          if (error.response.data.error) {
            if (error.response.data.error.message) message = error.response.data.error.message;
          }

          this.$swal({
            title: this.$t("general.error") + "!",
            html: `${message}`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (error.request) {
          this.$swal({
            title: this.$t("general.error") + "!",
            html: `${JSON.stringify(error.request)}`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (error.message) {
          this.$swal({
            title: this.$t("general.error") + "!",
            html: `${error.message}`,
            icon: "error",
            showConfirmButton: true,
          });
        } else {
          this.$swal({
            title: null,
            html: this.$t("errors.no_remaining_scan"),
            icon: "error",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.$t('general.go_on'),
            cancelButtonText: this.$t('general.contact_us'),
            confirmButtonColor: '#3085d6',
            cancelButtonColor: 'rgb(48, 133, 214)',
            reverseButtons: true,
          }).then((result) => {
            this.selectedTarget = null;
            if (result.isConfirmed) {
              //-- Silence is golden
            } else if (result.dismiss === 'cancel') {
              this.clickContactUsLink()
            }
          });
        }
      })
      .then(() => {
        this.$swal.hideLoading();
      });
  },
  hideExecuteTest() {
    this.selectedTarget = null;
  },
  onShowReports(data) {
    let target = new Target(data);
    this.$router.push({
      name: "reports-by-target",
      params: { engine: target.engine_abbreviation, targetId: target.id },
    });
  },
};
