import { capitalizeFirstLetter } from "../utilities";

export const reportsMethods = {
  async getFiles(id) {
    const parsedFileName = (filename) => {
      const arr = filename.split(".");
      return arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
    };

    try {
      const response = await this.reportService.get(`${id}/files`, {
        addSlash: false,
      });

      this.$emit("filesList", response.data.files);
      for (const file of response.data.files) {
        const arr = file.split(".");
        if (file.indexOf("report.zip") > -1 && !this.state.isRawDataRole) {
          continue;
        }
        const type = arr[1].toUpperCase();
        const name = arr[0]
            .replace(/swascan/gi, "")
            .replace(/_/g, " ")
            .replace(/-/g, " ")
            .trim();
        let icon = "pi ";
        if (type === "ZIP") {
          icon += "pi-cloud-download";
        } else if (type === "PDF") {
          icon += "pi-file-pdf";
        } else if (type === "XLS" || type === "XLSX" || type === "CSV") {
          icon += "pi-file-excel";
        } else {
          icon += "pi-file";
        }
        this.files.push({
          name: name + ` (${arr[1]})`,
          type,
          icon,
          originalName: file,
          url: `${id}/files/${file}`,
        });
      }

      if (this.reportFilesType && this.reportFilesType === "dti") {
        this.files = this.files.map((e) => ({
          ...e,
          priority: 0,
        }));

        let x = 25;
        if (this.files && this.files.length) {
          this.files = this.files.map((e) => {
            if (e.originalName.includes("executive_report")) return { ...e, priority: 1 };
            else if (e.originalName.includes("full_report")) return { ...e, priority: 2 };
            else if (e.originalName.includes("vulnerabilities_report")) return { ...e, priority: 3 };
            else if (e.originalName.includes("remediation_report_ita")) return { ...e, priority: 4 };
            else if (e.originalName.includes("remediation_report_eng")) return { ...e, priority: 5 };
            else {
              x++;
              return { ...e, priority: x };
            }
          });
        }

        this.files = this.files.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      }
    } catch (error) {
      console.error(`Error retrieving files list for report ${id}`, error);
    }
  },
  async download(f, onDownloadProgress) {
    if (f.name === this.showProgress) {
      return;
    }
    try {
      // this.showProgress = f.name;
      // const file = await this.reportService.get(f.url, {
      //   responseType: "blob",
      //   timeout: 7200000,
      //   onDownloadProgress: onDownloadProgress,
      // });
      // this.showProgress = null;
      // const url = window.URL.createObjectURL(file.data);
      const url = this.reportService.getFileUrl(f);
      const link = document.createElement("a");
      link.href = url;
      const filename = f.originalName.toLowerCase().indexOf("swascan-") === -1 ? "swascan-" + f.originalName : f.originalName;
      link.setAttribute("download", filename); //or any other extension
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  },
  checkReportLicense(currentRoute, routeName, engine) {
    if (!this.state.licenses || this.state.licenses.length === 0) {
      return setTimeout(() => this.checkReportLicense(currentRoute, routeName, engine), 200);
    }
    if (currentRoute === routeName) {
      const row = this.state.licenses.find((l) => l.engine === engine);
      if (!row) {
        return this.$router.back();
      }
    }
  },
};
