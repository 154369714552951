<template>
    <div class="p-grid">
        <div class="p-col-12" v-if="loading && isFull">
            <div class="card p-shadow-7">
                <AppLoader :shortLines="[1, 2]" :longLines="[1, 2, 3, 4]" />
            </div>
        </div>
        <div class="p-col-12" v-if="!loading && isVisible && isFull">
            <div class="card p-shadow-7">
                <div class="swa-header">
                    <h3>{{ $t("pages.report.hosts_down_list") }}</h3>
                </div>
                <DataTable
                    class="p-datatable-sm p-datatable-responsive"
                    :value="records"
                    :rowHover="true"
                    :filters="filters"
                    :totalRecords="count"
                    :rows="page_size"
                    :paginator="true"
                >
                    <Column :header="$t('general.ip_address')" sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data }}
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NScanIPDown",
    props: {
        summary: {
            type: Object,
            default: () => {
                return { target_down: [] };
            },
        },
        loading: {
            type: Boolean,
            default: () => true,
        },
        isVisible: {
            type: Boolean,
            default: () => true,
        },
        isFull: {
            type: Boolean,
            default: () => true,
        },
    },
    data() {
        return {
            filters: {},
            page_size: 10,
        };
    },
    computed: {
        records() {
            return this.summary ? this.summary.target_down : [];
        },
        count() {
            return this.summary && this.summary.target_down
                ? this.summary.target_down.length
                : 0;
        },
    },
};
</script>

<style>
</style>