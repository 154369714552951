export const ENV_VARS_ON_PREM = {
  VUE_APP_ENV: "on-prem",
  VUE_APP_IAM_REALM: "swa",
  VUE_APP_IAM_CLIENT_ID: "frontend",
  VUE_APP_I18N_LOCALE: "it",
  VUE_APP_I18N_FALLBACK_LOCALE: "it",
  VUE_APP_NAME: "frontend",
  VUE_APP_OLD_PLATFORM: "https://staging1.swascan.com/",
  VUE_APP_RESET_PASSWORD: "https://staging1.swascan.com/accounts/password/reset/",
  VUE_APP_SIGN_UP: "https://staging1.swascan.com/accounts/signup/",
  VUE_APP_PERMITTED_LICENSES: "nscan",
  VUE_APP_IAM_BASE_URL: "https://beta.swascan.com/auth",
  VUE_APP_API_BASE_URL: "https://core.beta.swascan.com/core",
  VUE_APP_BACKEND_BASE_URL: "https://backend.beta.swascan.com",
  VUE_APP_LICENSE_BASE_URL: "https://licensing.beta.swascan.com/licensing",
  VUE_APP_REST_BASE_URL: "https://rest.beta.swascan.com",
  VUE_APP_SWASCAN_INFO_EMAIL: "mailto:site@swascan.com",
  VUE_APP_VULNERABILITY_EARLY_WARNING_ENV_KEY: "STAGING",
  VUE_APP_FORCE_KEYCLOAK: "true",
  VUE_APP_FORCE_STANDALONE: "false",
  VUE_APP_PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhrVxsCwQsODUTdq0RsgcsHbeQYwCGgXH1yNzEcjJY1rB7oPFYxQwL/9m1Kp34+9oRtCAvBUma64ke9n58IZU7h+rSWqFAEZGawEHo66HEVhYzx2k94a67GWhnqfL+INY+OwJlAE/GblNl1MGTuUDNhrPZpUjyAvrNBp5l3gVlfAkz110gJ8/Otg1UWRpngu8EG2ukwJJNASDuvniOpBdL/cFj94enmxA7J4L0wKTXr7CgB3fK+dPyVajqqJdkgWuV3jYLIRu9gIhdW04QJF5dcnZQpXpYxcC7QAyjqX+kGQWIdmbZPZvgDizkLzh7q4RWPb4SJUcA2NBBE/7PrdVxwIDAQAB
-----END PUBLIC KEY-----`,
};
